// extracted by mini-css-extract-plugin
export var arrow = "_Button-module--arrow--5d614";
export var button = "_Button-module--button--dc776";
export var colorWhite = "_Button-module--color-white--e5759";
export var compact = "_Button-module--compact--0c5e8";
export var inner = "_Button-module--inner--e626c";
export var inverted = "_Button-module--inverted--ee26a";
export var marquee = "_Button-module--marquee--15aa8";
export var marqueeAnimation = "_Button-module--marqueeAnimation--d3607";
export var white = "_Button-module--white--624a2";
export var widthFull = "_Button-module--width-full--77bf3";