import React from 'react';
import * as styles from './styles/_Button.module.scss';
import cx from 'classnames';
import { Link } from 'gatsby';
import { RandomHoverEffect } from '../RandomHoverEffect/RandomHoverEffect';


function Button({
    children,
    onClick = e=>{},
    size = 'M',
    type = 'default',
    color = 'default',
    disabled = false,
    custom = false,
    role="button",
    exteral = false,
    compact = false,
    customData = {},
    white = false,
    inverted = false,
    arrow = true,
    to,
    ...props
}) {
    // define classes
    const classname = cx(
        styles.button,
        styles[`size-${size.toLowerCase()}`],
        styles[`type-${type.toLowerCase()}`],
        styles[`color-${color.toLowerCase()}`],
        { [`${styles.white}`]: white},
        { [`${styles.inverted}`]: inverted},
        { [`${styles.arrow}`]: arrow},
        { [`${styles.compact}`]: compact}
    );

    // render html
    if(exteral){

        return (
            <RandomHoverEffect>
                <a href={to} target="_blank" rel="noreferrer" className={classname} {...props} >
                    <div className={styles.inner}>
                        <span>{children}</span>
                        <span className={styles.marquee}>{children}</span>
                    </div>
                </a>
            </RandomHoverEffect>
        );

    }else if(custom){
        return (
            <RandomHoverEffect>
                <button state={customData} type={role} disabled={disabled} className={classname} onClick={e=>{ e.preventDefault(); onClick() }} {...props} >
                    <div className={styles.inner}>
                        <span>{children}</span>
                        <span className={styles.marquee}>{children}</span>
                    </div>
                </button>
            </RandomHoverEffect>
        );
    }else{

        return (
            <RandomHoverEffect>
                <Link type={role} disabled={disabled} className={classname} to={to} onClick={e=>onClick()} {...props} >
                    <div className={styles.inner}>
                        <span>{children}</span>
                        <span className={styles.marquee}>{children}</span>
                    </div>
                </Link>
            </RandomHoverEffect>
        );

    }
}

export default Button;
