import React from 'react';
import * as styles from './styles/container.module.scss';
import cx from 'classnames';

function Container({ children, element = 'div', classes, ...props }) {

    // define classes
    const classname = cx(
        styles.container,
        styles[classes]
    );


    if(element === "section"){
        return (
            <section className={classname}>
                <div className={styles.container__inner}>
                    {children}
                </div>
            </section>
        );
    }else{
        return (
            <div className={classname}>
                <div className={styles.container__inner}>
                    {children}
                </div>
            </div>
        );
    }

}

export default Container;
