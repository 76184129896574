import React from 'react';
import i18n from '../../../libs/i18n'; //eslint-disable-line

import "../../00_fundaments/_main.module.scss";

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { StaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

import propTypes from "prop-types";
import { AnimatePresence } from "framer-motion"



function Layout({ children,pageContext, ...props }) {


    return (
        <main>
            <StaticQuery
                query={graphql`
                    query LayoutQuery {
                    datoCmsSite {
                        globalSeo {
                        siteName
                        }
                        faviconMetaTags {
                        ...GatsbyDatoCmsFaviconMetaTags
                        }
                    }
                    }
                `}
                render={data => (
                    <>
                        <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags}>
                            <html lang="en" />
                            <meta charSet="utf-8" />
                            <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
                        </HelmetDatoCms>
                    </>
                )}
            />
            <Header />
            <AnimatePresence exitBeforeEnter initial={false}>
                {children}
            </AnimatePresence>
            <Footer />
        </main>
    );
}

Layout.propTypes = {
    children: propTypes.node.isRequired
  }

export default Layout;