import { Link } from "gatsby"
import React from "react"
import * as styles from "./styles/applogo.module.scss"

function AppLogo(props) {

  return (
    <Link to="/" className={styles.applogo}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={57}
        viewBox="0 0 50 57"
        fill="none"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M24.398 24.275c0 .876.487 1.44 1.437 1.461l10.775.245c1.822.042 3.75-.124 4.76-.379 4.368-1.101 6.798-4.319 6.88-9.316 0-5.025-2.462-9.092-6.854-11.476-1.115-.606-3.023-1.289-4.428-1.675L25.848.077c-.959-.264-1.45.164-1.45 1.055v23.143Zm14.088 4.372c1.442-.01 3.397.144 4.538.45h-.002c4.481 1.203 6.982 4.804 6.976 10.064-.087 5.252-2.513 9.355-6.965 11.845-1.509.845-3.25 1.486-4.979 1.967l-12.277 3.418c-.912.254-1.38-.15-1.38-.998V30.15c0-.863.472-1.41 1.395-1.417l12.694-.085ZM.706 51.207A.97.97 0 0 1 0 50.268l.028-4.748c.022-3.614 1.283-5.27 3.837-4.932l11.088 1.465c3.595.475 5.562 3.066 5.562 7.692v5.75a.97.97 0 0 1-1.232.934L.707 51.206v.001Zm15.67-1.799c0-.988-.275-1.258-1.399-1.482L3.815 45.71c-.837-.167-1.04-.024-1.043.814v.182l13.604 2.916v-.214ZM.085 34.813l-.017 2.953a.97.97 0 0 0 .985.975l18.508-.297a.969.969 0 0 0 .954-.97v-6.572a.969.969 0 0 0-.852-.962L1.208 27.689a.97.97 0 0 0-1.086.957L.106 31.35a.97.97 0 0 0 .862.97l11.46 1.285v.081l-11.387.163a.97.97 0 0 0-.956.963Zm.053-10.037.016-2.712a.97.97 0 0 1 .903-.96l14.45-.994a.968.968 0 0 0 .902-.966l.004-3.165a.97.97 0 0 1 .824-.958l2.162-.33a.97.97 0 0 1 1.116.959v9.132a.97.97 0 0 1-.97.97H1.108a.97.97 0 0 1-.97-.976Zm.056-9.395a.97.97 0 0 0 1.123.964l18.381-2.938a.97.97 0 0 0 .817-.957V8.37a.97.97 0 0 0-1.183-.945L10.461 9.42v-.039c.002-.824.213-1.266 1.217-1.507l8.094-1.943a.969.969 0 0 0 .743-.942V.973a.969.969 0 0 0-1.252-.927l-7.045 2.146c-1.916.584-3.063 1.664-3.615 3.319l-.056.015c-.562-1.323-1.641-1.715-3.33-1.2l-1.189.363C1.492 5.46.24 7.627.22 11.174L.194 15.38Zm5.85-6.155c.896-.215 1.143-.048 1.14.782h-.001v.15l-4.24.955v-.142c.004-.787.204-1.05 1.035-1.249l2.066-.496Z"
          clipRule="evenodd"
        />
      </svg>
    </Link>
  )
}

export default AppLogo
