exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-client-work-js": () => import("./../../../src/templates/clientWork.js" /* webpackChunkName: "component---src-templates-client-work-js" */),
  "component---src-templates-job-detail-js": () => import("./../../../src/templates/jobDetail.js" /* webpackChunkName: "component---src-templates-job-detail-js" */),
  "component---src-templates-work-detail-js": () => import("./../../../src/templates/workDetail.js" /* webpackChunkName: "component---src-templates-work-detail-js" */)
}

