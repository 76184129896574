import React from 'react';
import * as styles from './styles/_Heading.module.scss';
import cx from 'classnames';

export const Heading = ({children, size = 'm', align="left", uppercase=false, spacing = 'default', weight = '700', element = 'h2', font = 'special', ...props}) => {

    // define classes
    const classname = cx(
        styles.heading,
        styles[`size_${size}`],
        styles[`spacing_${spacing}`],
        styles[`weight_${weight}`],
        styles[`align_${align}`],
        styles[`font_${font}`],
        { [`${styles.uppercase}`]: uppercase},
    );

    // render html
    return (
        <>
            {element === 'h1'  && <h1 className={classname}>{children}</h1>}
            {element === 'h2'  && <h2 className={classname}>{children}</h2>}
            {element === 'h3'  && <h3 className={classname}>{children}</h3>}
            {element === 'h4'  && <h4 className={classname}>{children}</h4>}
            {element === 'h5'  && <h5 className={classname}>{children}</h5>}
            {element === 'div' && <div className={classname}>{children}</div>}
        </>
    );

};