import React, { useRef,useState,useEffect, useMemo } from 'react';
import * as styles from './randomhovereffect.module.scss';
import throttle from 'lodash.throttle';
import cx from 'classnames';

export const RandomHoverEffect = ({children,name, isCard = false, ...props}) => {

    // define classes
    const classname = cx(
        styles.randomhovereffect,
        { [`${styles.typeCard}`]: isCard},
    );

    // render html
    const cursorClasses = [styles.hand, styles.mouth, styles.eye];
    const parentRef = useRef(null);
    const [hoverEffect, setHoverEffect] = useState(cursorClasses[0]);


    const eventHandler = () => {
        // handle the event...
        setHoverEffect(cursorClasses[Math.floor(Math.random() * cursorClasses.length)]);
      };

    const throttledEventHandler = useMemo(() => throttle(eventHandler, 200), []);



    useEffect(() => {

        const parent = parentRef.current;
        cursorClasses.forEach((currentClass,index) => {
            parent.classList.remove(currentClass);
        })
        parent.classList.add(hoverEffect);
    }, [hoverEffect]);

    return (
        <div className={classname} ref={parentRef} onMouseMove={throttledEventHandler}>
            {children}
        </div>
    );



};