import React from "react"
import * as styles from "./styles/footer.module.scss"
import { useTranslation } from "react-i18next"
import Container from "../Container/Container"
import { Heading } from "../../01_atoms/Heading/Heading"

function Footer({ children, ...props }) {
  const { t } = useTranslation()

  return (
    <>
      <footer id="footer" className={styles.footer}>
        <Container>
          <div className={styles.footer__inner}>
            <div className={styles.footer__heading}>
              <Heading element="h3" size="m">
                {t("footer_title")}
              </Heading>
            </div>

            <div className={styles.footer__blocks}>
              <div className={styles.footer__block}>
                <Heading element="h4" size="s" spacing="m" font="main">
                  <span>{t("footer_contact_title")}</span>
                </Heading>
                <ul className={styles.footer__list}>
                  <li>
                    <a
                      className={styles.footer__link}
                      href="mailto:maarten.vanthemsche@ddb.be"
                    >
                      maarten.vanthemsche@ddb.be
                    </a>
                  </li>
                  <li>
                    <a className={styles.footer__link} href="tel:+3226797520 ">
                      +322 679 75 20
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.footer__block}>
                <Heading element="h4" size="s" spacing="m" font="main">
                  {t("footer_location_title")}
                </Heading>
                <ul className={styles.footer__list}>
                  <li>
                    <a
                      className={styles.footer__link}
                      href="https://maps.app.goo.gl/qpyqf7YRjdSdpWDq5"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Scheldestraat 122<br/>
                      1080 Brussels, Belgium
                    </a>
                  </li>
                  <li>&nbsp;</li>
                  <li>
                    <a
                      className={styles.footer__link}
                      href="https://maps.app.goo.gl/4LWr6CFgmhi5bBjA7"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Grotehondstraat 44<br/>
                      2018 Antwerp, Belgium
                    </a>
                  </li>
                  <li>&nbsp;</li>
                </ul>
                <Heading element="h4" size="s" spacing="m" font="main">
                  {t("footer_location_officetitle")}
                </Heading>
                <ul className={styles.footer__list}>
                  <li>
                    <a
                      className={styles.footer__link}
                      href="https://goo.gl/maps/GzVWUpBp2o7niqKS6"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Excelsiorlaan 75-77<br/>
                      1930 Zaventem, Belgium
                    </a>
                  </li>
                </ul>
              </div>
              <div className={styles.footer__block}>
                <Heading element="h4" size="s" spacing="m" font="main">
                  {t("footer_social_title")}
                </Heading>
                <ul className={styles.footer__list}>
                  <li>
                    <a
                      className={styles.footer__link}
                      href="https://www.instagram.com/dvlr.ddb/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <svg
                        width="36"
                        height="35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M17.647 3.153c4.673 0 5.226.018 7.072.102 1.706.078 2.632.363 3.25.602.816.318 1.399.697 2.012 1.31a5.412 5.412 0 011.308 2.012c.24.616.525 1.543.603 3.25.084 1.844.102 2.398.102 7.07 0 4.674-.018 5.227-.102 7.072-.078 1.706-.363 2.633-.603 3.25a5.43 5.43 0 01-1.308 2.012 5.411 5.411 0 01-2.013 1.309c-.617.24-1.543.524-3.25.602-1.844.084-2.398.102-7.07.102-4.674 0-5.227-.018-7.072-.102-1.707-.078-2.633-.362-3.25-.602a5.43 5.43 0 01-2.012-1.309 5.412 5.412 0 01-1.31-2.012c-.239-.617-.524-1.544-.601-3.25-.084-1.845-.103-2.398-.103-7.071s.019-5.226.103-7.072c.077-1.706.362-2.633.602-3.25.317-.816.697-1.4 1.309-2.012a5.412 5.412 0 012.012-1.309c.617-.24 1.544-.524 3.25-.602 1.845-.084 2.398-.102 7.071-.102zm0-3.153c-4.753 0-5.349.02-7.215.106-1.863.084-3.134.38-4.248.813a8.57 8.57 0 00-3.1 2.019 8.572 8.572 0 00-2.018 3.099C.634 7.15.338 8.422.253 10.284.168 12.151.147 12.747.147 17.5s.02 5.349.106 7.215c.085 1.862.38 3.134.813 4.248a8.57 8.57 0 002.02 3.1 8.577 8.577 0 003.099 2.018c1.113.433 2.385.729 4.247.813 1.867.086 2.463.106 7.216.106s5.349-.02 7.215-.106c1.863-.084 3.135-.38 4.248-.813a8.57 8.57 0 003.1-2.019 8.576 8.576 0 002.018-3.1c.433-1.112.729-2.385.814-4.247.085-1.866.105-2.462.105-7.215s-.02-5.35-.105-7.216c-.085-1.862-.381-3.135-.814-4.247a8.57 8.57 0 00-2.018-3.1 8.576 8.576 0 00-3.1-2.019C27.997.486 26.725.19 24.863.106 22.995.02 22.4 0 17.646 0zm.148 8.514a8.986 8.986 0 100 17.972 8.986 8.986 0 000-17.972zm0 14.819a5.833 5.833 0 110-11.666 5.833 5.833 0 010 11.666zM29.237 8.158a2.1 2.1 0 11-4.2 0 2.1 2.1 0 014.2 0z"
                          fill="#fff"
                        />
                      </svg>
                    </a>
                    <a
                      className={styles.footer__link}
                      href="https://www.facebook.com/DVLRDDB"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <svg
                        width="36"
                        height="35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.442 17.607C35.442 7.883 27.542 0 17.795 0 8.05 0 .148 7.883.148 17.607c0 8.788 6.453 16.072 14.89 17.393V22.697h-4.48v-5.09h4.48v-3.88c0-4.412 2.635-6.85 6.665-6.85 1.931 0 3.95.345 3.95.345v4.333H23.43c-2.193 0-2.876 1.357-2.876 2.75v3.302h4.894l-.782 5.09h-4.112V35c8.436-1.32 14.89-8.605 14.89-17.393z"
                          fill="#fff"
                        />
                      </svg>
                    </a>
                    <a
                      className={styles.footer__link}
                      href="https://linkedin.com/company/dvlr-ddb/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <svg
                        width="36"
                        height="35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.15 0H7.735A7.292 7.292 0 00.442 7.292v20.416A7.292 7.292 0 007.734 35h20.417a7.292 7.292 0 007.291-7.292V7.292A7.292 7.292 0 0028.151 0zM12.11 27.708H7.734V11.667h4.375v16.041zM9.922 9.818a2.563 2.563 0 01-2.553-2.573 2.563 2.563 0 012.553-2.572 2.563 2.563 0 012.552 2.572 2.562 2.562 0 01-2.552 2.573zm19.687 17.89h-4.375v-8.172c0-4.912-5.833-4.54-5.833 0v8.172h-4.375V11.667H19.4v2.574c2.036-3.772 10.208-4.05 10.208 3.61v9.857z"
                          fill="#fff"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.footer__legal}>
              <a
                className={styles.footer__link}
                href="/docs/DDB-BE_Privacy-Notice.pdf?v=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy statement
              </a>
              <a
                className={styles.footer__link}
                href="/docs/DDB-BE_Cookie-Notice.pdf?v=2"
                target="_blank"
                rel="noreferrer noopener"
              >
                Cookie statement
              </a>
              <span
                className={styles.footer__link}
                onClick={() => { if(typeof window !== "undefined") { window.OneTrust.ToggleInfoDisplay(); } }}
              >
                Cookie management
              </span>
            </div>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer
