import React, { useEffect, useState } from 'react';
import Button from '../../01_atoms/Button/Button';

import * as styles from './styles/header.module.scss';

import { useTranslation } from "react-i18next";
import AppLogo from '../../01_atoms/AppLogo/AppLogo';
import { Offcanvas } from '../../01_atoms/Offcanvas/Offcanvas';
import { Link } from 'gatsby';
import Container from '../Container/Container';
import cx from 'classnames';

import { gsap,ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Heading } from '../../01_atoms/Heading/Heading';




function Header({children,...props}) {

    const [active, setActive] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const {t} = useTranslation();

    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollToPlugin);

    function onContactClick() {
        gsap.to(window, {duration: .75, scrollTo:'#footer'});
    }

    function toggleNavigation(){
        setActive(!active);
    }

    function onNavClicked(){
        setActive(false);
    }

    useEffect(() => {
 
        ScrollTrigger.create({
            start: '10px top',
            end: 99999,
            onEnter: () =>{setScrolled(true)},
            onLeaveBack: () => {setScrolled(false)}
          });
     }, []);

    return (
        <>
            <div className={ cx(styles.background, { [`${styles.visible}`]: active})}></div>
            <div className={styles.header__push}></div>
            <header className={ cx(styles.header, { [`${styles.headerActive}`]: active}, { [`${styles.scrolled}`]: scrolled})}> 
                    
                    <Container>
                        <div className={styles.header__inner}>
                            <AppLogo inverted={active} />

                            <div className={styles.header__links}>
                                
                                <Button custom arrow={false} onClick={onContactClick}>{t('header_contact')}</Button>      


                                <Offcanvas onToggle={toggleNavigation} active={active} />

                                <ul className={cx(styles.navigation, styles.navigationDesktop)}>
                                    <li className={styles.navigation__item}>
                                        <Link to={"/work"}>{t('header_navigation_work')}</Link>
                                    </li>
                                    <li className={styles.navigation__item}>
                                        <Link to={"/about"}>{t('header_navigation_about')}</Link>
                                    </li>
                                    <li className={styles.navigation__item}>
                                        <Link to={"/jobs"}>{t('header_navigation_jobs')}</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Container>
                    


                <ul className={cx(styles.navigation, styles.navigationMobile)}>
                    <li className={styles.navigation__item}>
                        <Link onClick={onNavClicked} to={"/"}><Heading element="h3" size="l" uppercase>{t('header_navigation_home')}</Heading></Link>
                    </li>
                    <li className={styles.navigation__item}>
                        <Link onClick={onNavClicked} to={"/work"}><Heading element="h3" size="l" uppercase>{t('header_navigation_work')}</Heading></Link>
                    </li>
                    <li className={styles.navigation__item}>
                        <Link onClick={onNavClicked} to={"/about"}><Heading element="h3" size="l" uppercase>{t('header_navigation_about')}</Heading></Link>
                    </li>
                    <li className={styles.navigation__item}>
                        <Link onClick={onNavClicked} to={"/jobs"}><Heading element="h3" size="l" uppercase>{t('header_navigation_jobs')}</Heading></Link>
                    </li>
                </ul>

            </header>
        </>
    );
}

export default Header;