import React from 'react';
import * as styles from './styles/_Offcanvas.module.scss';
import cx from 'classnames';

export const Offcanvas = ({ onToggle = ()=>{}, active, ...props }) => {

    // define classes
    const classname = cx(
        styles.offcanvas,
        { [`${styles.active}`]: active}
    );

    // render html
    return (
        <button onClick={()=>onToggle()} className={classname}>
            <span></span>
        </button>
    );

};