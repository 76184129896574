// extracted by mini-css-extract-plugin
export var align_center = "_Heading-module--align_center--8beed";
export var align_left = "_Heading-module--align_left--9c830";
export var align_right = "_Heading-module--align_right--bfbda";
export var font_main = "_Heading-module--font_main--8bdc8";
export var font_special = "_Heading-module--font_special--47ab8";
export var font_special10 = "_Heading-module--font_special10--e30d3";
export var heading = "_Heading-module--heading--a7874";
export var size_l = "_Heading-module--size_l--f35f6";
export var size_m = "_Heading-module--size_m--1cb21";
export var size_s = "_Heading-module--size_s--25a7f";
export var size_xl = "_Heading-module--size_xl--22416";
export var size_xs = "_Heading-module--size_xs--40587";
export var size_xxl = "_Heading-module--size_xxl--41c6d";
export var size_xxxl = "_Heading-module--size_xxxl--fdce8";
export var spacing_0 = "_Heading-module--spacing_0--39a4c";
export var spacing_l = "_Heading-module--spacing_l--e2d59";
export var spacing_m = "_Heading-module--spacing_m--ea1ed";
export var spacing_s = "_Heading-module--spacing_s--91274";
export var spacing_xl = "_Heading-module--spacing_xl--1d0c4";
export var spacing_xxl = "_Heading-module--spacing_xxl--87fb2";
export var spacing_xxxl = "_Heading-module--spacing_xxxl--7b76a";
export var uppercase = "_Heading-module--uppercase--a2ecd";
export var weight_400 = "_Heading-module--weight_400--79653";
export var weight_500 = "_Heading-module--weight_500--476ed";
export var weight_600 = "_Heading-module--weight_600--ede45";
export var weight_700 = "_Heading-module--weight_700--078cd";
export var weight_800 = "_Heading-module--weight_800--98ea1";
export var weight_900 = "_Heading-module--weight_900--08fd2";